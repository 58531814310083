import React from 'react'

import { Carousel } from '../../components/carousel'

const videos = [
  'https://sissamx.com.mx/hi-sports/videos/05.mp4',
  'https://sissamx.com.mx/hi-sports/videos/06.mp4',
  'https://sissamx.com.mx/hi-sports/videos/07.mp4'
]

function Construction() {
  return (
    <>
      <h1 className='text-[4rem] md:text-[6rem] text-font '>en pretemporada</h1>
      <div className='pl-[2%] pr-[2%] lg:pl-[10%] lg:pr-[10%] block lg:flex justify-between items-strech'>
        <div className='basis-2/3'>
          <a
            href='https://online.caliente.mx/promoRedirect?member=hisports&campaign=DEFAULT&channel=DEFAULT&zone=54623980&lp=0'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='h-[100%] hidden lg:block'
              src='https://sissamx.com.mx/hi-sports/images/home/160x600.png'
              alt='caliente-publicidad-160x600.png'
            />
          </a>
        </div>
        <Carousel className='basis-1/8'>
          {videos.map((video) => (
            <video src={video} key={video} autoPlay loop muted />
          ))}
        </Carousel>
        <div className='basis-2/3'>
          <a
            href='https://online.caliente.mx/promoRedirect?member=hisports&campaign=DEFAULT&channel=DEFAULT&zone=54623980&lp=0'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='h-[100%] hidden lg:block'
              src='https://sissamx.com.mx/hi-sports/images/home/160x600.png'
              alt='caliente-publicidad-160x600.png'
            />
          </a>
        </div>
      </div>
      <div className='pl-[2%] pr-[2%] pb-[2%] lg:pl-[10%] lg:pr-[10%]'>
        <a
          href='https://online.caliente.mx/promoRedirect?member=hisports&campaign=DEFAULT&channel=DEFAULT&zone=54623980&lp=0'
          target='_blank'
          rel='noreferrer'
        >
          <img
            className='w-screen h-auto'
            src='https://sissamx.com.mx/hi-sports/images/home/970x250.png'
            alt='construccion.png'
          />
        </a>
      </div>
    </>
  )
}

export default Construction
