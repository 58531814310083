import './App.css'

import Construction from './pages/construction'

function App() {
  return (
    <main
      className={
        'flex min-h-screen flex-col items-center align-middle justify-center bg-example-bg bg-cover bg-center bg-fixed'
      }
    >
      <Construction />
    </main>
  )
}

export default App
